import {APP_CONSTANT} from '../../../components/helperComponents/Constants'
import {errorFlag} from '../../actions/ErrorActions'
import {apiRequest} from '../../actions/ApiActions'
import {BANNER_FEATURE, BANNER_TYPE} from '../../../components/error/ErrorBanner'
import {setLoadingFlag} from '../../actions/LoadingActions'
import i18n from 'i18next'
import {getBrandedTranslation} from '../../../components/helperComponents/contentUtils'
import { bffAggregatorAPI } from '../../actions/BffActions'

export const saveConsentsMiddleware = (store) => (next) => (action) => {
  next(action)

  function getSaveConsentHeaders() {
    return {
      'Application-Id': APP_CONSTANT.CONFIG.APPLICATION_ID,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Headers': '*',
      'X-B3-SpanId': APP_CONSTANT.CONFIG.SPAN_ID,
      'X-B3-TraceId': APP_CONSTANT.CONFIG.TRACE_ID,
    }
  }

  switch (action.type) {
    case '[CONSENTS] UNLINK': {
      if (APP_CONSTANT.CONFIG.IS_OWNER) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
      dispatchConsentRequest()
      break
    }

    case '[MAINTAIN CONSENT]': {
      dispatchConsentRequest()
      break
    }

    case '[REMOVE CONSENTS] API_SUCCESS': {
      store.dispatch(bffAggregatorAPI())
      next(
        errorFlag({
          state: BANNER_TYPE.SUCCESS,
          errorStatus: true,
          message: i18n.t('lbl_remove_success'),
          feature: BANNER_FEATURE.SUCCESS_REMOVE,
        }),
      )
      break
    }

    case '[MAINTAIN CONSENT] API_SUCCESS': {
      store.dispatch(bffAggregatorAPI())
      break
    }

    case '[REMOVE CONSENTS] API_ERROR': {
      console.log(`Error from remove consents API for source ${action.type}`)
      const errorMsg = APP_CONSTANT.CONFIG.IS_OWNER
        ? i18n.t('lbl_remove_consent_failure_web_part1')
        : getBrandedTranslation('lbl_remove_consent_failure')
      handleApiError(errorMsg)
      break
    }

    case '[MAINTAIN CONSENT] API_ERROR': {
      console.log(`Error from maintain consent API for source ${action.type}`)
      const errorMsg = APP_CONSTANT.CONFIG.IS_OWNER
        ? i18n.t('lbl_maintain_privacy_error_web_part1')
        : getBrandedTranslation('lbl_maintain_privacy_error')
      handleApiError(errorMsg)
      break
    }
  }

  function handleApiError(errorMsg) {
    next(
      errorFlag({
        state: BANNER_TYPE.ERROR,
        errorStatus: true,
        message: errorMsg,
        feature: BANNER_FEATURE.MAINTAIN_PRIVACY,
      }),
    )
    store.dispatch({type: `[SPINNER] STOP`})
    store.dispatch(setLoadingFlag({loadingFlag: true}))
  }

  function dispatchConsentRequest() {
    store.dispatch({type: `[SPINNER] START`})

    const request = {
      body: action.payload.consents,
      method: 'POST',
      url:
          process.env.NODE_ENV === 'production'
              ? `${APP_CONSTANT.CONFIG.APIGEE_URL}/api/privacybff/consent?thirdPartyApplicationId=${action.payload.applicationId}`
              // : `https://api.qa01e.gcp.ford.com/api/privacybff/consent?applicationId=${action.payload.applicationId}`,
              : `https://localpcu.ford.com:8080/api/privacybff/consent?thirdPartyApplicationId=${action.payload.applicationId}`,
      feature: '[MAINTAIN CONSENT]',
      headers: getSaveConsentHeaders(),
      responseType: 'json',
    }

    next(apiRequest(request))
  }
}
