import {apiRequest} from '../../actions/ApiActions'
import {APP_CONSTANT} from '../../../components/helperComponents/Constants'
import {
  normalizeConsentDataFromAggregatorResponse,
  normalizePrivacyDataFromAggregatorResponse,
} from '../../actions/ExperienceLayerActions'
import {errorFlag} from '../../actions/ErrorActions'
import {setVins} from '../../actions/VehicleActions'
import {setAuthorizedusersForVin, setUserGuid, setUserState} from '../../actions/AuthorizedUsersActions'
import {BANNER_FEATURE, BANNER_TYPE} from '../../../components/error/ErrorBanner'
import {analytics} from '../../../components/helperComponents/Analytics/Analytics'
import i18n from 'i18next'
import {finishApiRefreshing, setLoadingFlag} from '../../actions/LoadingActions'
import {getBrandedTranslation} from '../../../components/helperComponents/contentUtils'
import {bffAuthorizeAPI} from "../../actions/BffActions";
import {updateAuthentication} from "../../actions/InitialSetupActions";
import {utils} from "../../../components/helperComponents/Utils";

export const experienceLayerMiddleware = (store) => (next) => (action) => {
  next(action)
  switch (action.type) {
    case 'CALL EXPERIENCE LAYER API':
      next(
        apiRequest({
          method: 'GET',
          url:
            process.env.NODE_ENV === 'production'
              ? `${APP_CONSTANT.CONFIG.APIGEE_URL}/api/privacybff/aggregator`
              // : 'https://api.qa01e.gcp.ford.com/api/privacybff/aggregator',
              : 'https://localpcu.ford.com:8080/api/privacybff/aggregator',
          feature: '[EXPERIENCE LAYER]',
          headers: getExperienceLayerHeaders(),
          responseType: 'json',
        }),
      )
      break

      case 'CALL FORDPASS AGGREGATOR API':
          next(
              apiRequest({
                  method: 'GET',
                  url:
                      process.env.NODE_ENV === 'production'
                          ? `${APP_CONSTANT.CONFIG.APIGEE_URL}/api/privacybff/aggregator-fordpass`
                          // : 'https://api.qa01e.gcp.ford.com/api/privacybff/aggregator-fordpass',
                          : 'https://localpcu.ford.com:8080/api/privacybff/aggregator-fordpass',
                  headers: {
                      'Content-Type': 'application/json',
                      'Cache-Control': 'no-cache',
                      'X-B3-SpanId': APP_CONSTANT.CONFIG.SPAN_ID,
                      'X-B3-TraceId': APP_CONSTANT.CONFIG.TRACE_ID,
                      'Access-Control-Allow-Headers': '*',
                      'bffId': utils.getBffIdFromQueryParam(),
                      'CountryCode': APP_CONSTANT.CONFIG.COUNTRY_CODE
                  },
                  responseType: 'json',
              }),
          )
          break

    case '[EXPERIENCE LAYER] API_SUCCESS':
      try {
        const dataResponse = action.payload.data

        // uncomment above before commit and Uncomment below for test data

        // let dataResponse = []
        // if (APP_CONSTANT.CONFIG.COUNTRY_CODE === 'USA') {
        //     dataResponse = testDataUSA2
        // } else if (APP_CONSTANT.CONFIG.COUNTRY_CODE === 'CAN') {
        //     dataResponse = testDataCAN
        // } else {
        //     dataResponse = testDataGBR2
        // }

        store.dispatch({type: `[SPINNER] STOP`})
          store.dispatch(setLoadingFlag({loadingFlag: true}))

            store.dispatch(updateAuthentication({authStatus: true}))

        if (store.getState().pullToRefreshFlag === false && dataResponse.status === 207) {
          if (
            dataResponse.value.error.Consents.length !== 0 ||
            dataResponse.value.error.Privacies.length !== 0
          ) {
            throw new Error(
              `Error in Experience Layer ${getAggregatedErrorMessages(dataResponse.value.error)}`,
            )
          } else if (
            dataResponse.value.authorizedVehicleList.length === 0 &&
            dataResponse.value.error.GarageVehicles.length !== 0
          ) {
            throw new Error(
              `Error in Vehicle details from Experience Layer ${getAggregatedErrorMessages(
                dataResponse.value.error,
              )}`,
            )
          } else if (dataResponse.value.error.GarageVehicles.length !== 0)
            vinErrorBanner(store, action, dataResponse)
        }

        store.dispatch(setAuthorizedusersForVin(dataResponse.value.authorizedUsersForVin))
        store.dispatch(setUserGuid(dataResponse.value.userGuid))
        APP_CONSTANT.CONFIG.USER_GUID = dataResponse.value.userGuid
        store.dispatch(setUserState(dataResponse.value.userState))
        analytics.init(window.app_properties.amplitudeApiKey, dataResponse.value.userGuid)

        store.dispatch(
          setVins({
            authorizedVinsList: filterVinsByBrand(dataResponse.value.authorizedVehicleList),
            garageVinList: filterVinsByBrand(dataResponse.value.garageVehicleList),
          }),
        )

        store.dispatch(
          normalizeConsentDataFromAggregatorResponse({
            data: dataResponse.value,
            normalize: true,
          }),
        )

        store.dispatch(
          normalizePrivacyDataFromAggregatorResponse({
            data: dataResponse.value,
          }),
        )

        store.dispatch(finishApiRefreshing())
      } catch (error) {
        if (error.message.includes('Error in Experience Layer')) {
          experienceLayerError(store, action, error)
        } else if (error.message.includes('Error in Vehicle details from Experience Layer')) {
          allVehiclesErrorBanner(store, action, error)
        } else {
          console.log(
            `Error after get consents api success for source ${action.type} and error details: ${error}`,
          )
          store.dispatch(
            errorFlag({
              state: BANNER_TYPE.ERROR,
              errorStatus: true,
              message: getGenericMessage(),
              feature: BANNER_FEATURE.GENERIC,
            }),
          )
        }
      }
      break
    case '[EXPERIENCE LAYER] API_ERROR':
      console.log(`Error from experience layer API for source ${action.type}`)
        if (action.payload.response && action.payload.response.status === 401) {
            // call authorize api
            store.dispatch(bffAuthorizeAPI())
        }
      break
  }
}

function filterVinsByBrand(dataResponse) {
  return dataResponse.filter((vin) => vin.brand.charAt(0) === APP_CONSTANT.CONFIG.BRAND_CODE)
}

function getExperienceLayerHeaders() {
  return {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-B3-SpanId': APP_CONSTANT.CONFIG.SPAN_ID,
    'X-B3-TraceId': APP_CONSTANT.CONFIG.TRACE_ID,
    'Access-Control-Allow-Headers': '*',
    CountryCode: APP_CONSTANT.CONFIG.COUNTRY_CODE,
    brand: APP_CONSTANT.CONFIG.BRAND_CODE,
    region: APP_CONSTANT.CONFIG.REGION,
    onset: APP_CONSTANT.CONFIG.ONSET,
  }
}

function getAggregatedErrorMessages(error) {
  return error.Consents.join(' ') + error.Privacies.join(' ')
}

function getGenericMessage() {
  return APP_CONSTANT.CONFIG.IS_OWNER
    ? i18n.t('lbl_generic_error_web_part1')
    : getBrandedTranslation('lbl_generic_error')
}

function allVehiclesErrorBanner(store, action, error) {
  console.log(
    `Error after get consents api success for source ${action.type} and error details: ${error.toString()}`,
  )
  store.dispatch(
    errorFlag({
      state: BANNER_TYPE.ERROR,
      errorStatus: true,
      message: getGenericMessage(),
      feature: BANNER_FEATURE.EXPERIENCE_LAYER,
    }),
  )
}

function experienceLayerError(store, action, error) {
  console.log(
    `Error after get consents api success for source ${action.type} and error details: ${error.toString()}`,
  )

  store.dispatch(
    errorFlag({
      state: BANNER_TYPE.ERROR,
      errorStatus: true,
      message: APP_CONSTANT.CONFIG.IS_OWNER
        ? i18n.t('lbl_experience_layer_error_web_part1')
        : getBrandedTranslation('lbl_experience_layer_error'),
      feature: BANNER_FEATURE.EXPERIENCE_LAYER,
    }),
  )
}

function vinErrorBanner(store, action, dataResponse) {
  console.log(
    `Vin error for source ${
      action.type
    }: Error in Experience Layer vehicle data ${dataResponse.value.error.VINs.join()}`,
  )
  store.dispatch(
    errorFlag({
      state: BANNER_TYPE.ERROR,
      errorStatus: true,
      message: APP_CONSTANT.CONFIG.IS_OWNER
        ? i18n.t('lbl_vehicles_error_web_part1')
        : getBrandedTranslation('lbl_vehicles_error'),
      feature: BANNER_FEATURE.CLOSE_BANNER,
    }),
  )
}
