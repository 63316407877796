import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import './styles/index.scss'
import {useDispatch, useSelector} from 'react-redux'
import ErrorBanner from './components/error/ErrorBanner'
import InternetBanner from './components/error/InternetBanner'
import Spinner from './components/Spinner'
import {APPLICATION_PATH} from './components/helperComponents/Constants'
import ApplicationLanding from './components/main/ApplicationLanding'
import ManageDataWithFord from './components/manageData/ManageDataWithFord'
import ManageDataWithThirdParty from './components/manageData/ManageDataWithThirdParty'
import MobileAppCookies from './components/feature/MobileAppCookies'
import UnlinkFeature from './components/feature/UnlinkFeature'
import {useTranslation} from 'react-i18next'
import {bffAggregatorAPI, bffFordPassAggregatorAPI} from "./redux/actions/BffActions";
import {utils} from "./components/helperComponents/Utils";

export default function App() {
  const {ready} = useTranslation()
  const isAuthenticated = useSelector((state) => state.isAuthenticated)
  const dispatch = useDispatch()
  const {loadingFlag} = useSelector((state) => state.loading)
  const spinnerWebFlag = useSelector((state) => state.spinnerWebFlag)

  window.addEventListener('online', () => dispatch({type: `INTERNET ERROR`, payload: false}))
  window.addEventListener('offline', () => dispatch({type: `INTERNET ERROR`, payload: true}))

  useEffect(() => {
    dispatch({type: `[SPINNER] START`})

    utils.initialSetUpValues()
    utils.getFavicon()
    utils.setBrandDataSet()
    utils.getBackgroundColor()
    if (utils.getBffIdFromQueryParam() !== undefined) {
      dispatch(bffFordPassAggregatorAPI())
    } else {
      dispatch(bffAggregatorAPI())
    }
  }, [])

  function renderTopBanners() {
    return (
      <>
        <InternetBanner />
        <ErrorBanner />
      </>
    )
  }

  return (
    <>
      <div className="max-width">
        {spinnerWebFlag && <Spinner />}
        <div>
          <Router>
            {renderTopBanners()}
            {ready && isAuthenticated && loadingFlag && (
              <Routes>
                <Route path={APPLICATION_PATH.LANDING} element={<ApplicationLanding />} />
                <Route path={APPLICATION_PATH.MANAGE_DATA_WITH_FORD} element={<ManageDataWithFord />} />
                <Route
                  path={APPLICATION_PATH.MANAGE_DATA_WITH_THIRD_PARTIES}
                  element={<ManageDataWithThirdParty />}
                />
                <Route path={APPLICATION_PATH.UNLINK_FEATURE} element={<UnlinkFeature />} />
                <Route path={APPLICATION_PATH.MOBILE_APP_COOKIES} element={<MobileAppCookies />} />
              </Routes>
            )}
          </Router>
        </div>
      </div>
    </>
  )
}
