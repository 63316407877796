export const loadingMiddleware = (store) => (next) => (action) => {
  next(action)
  switch (action.type) {
    case '[SPINNER] START':
      //if webkit is only available in IOS, webkit.messageHandlers event is sent to fordpass IOS
      if (window.app_properties.onset) {
        store.dispatch({type: `SPINNER WEB FLAG`, payload: true})
      } else if (window.webkit) {
        window.webkit.messageHandlers.loadingstatus.postMessage('Loading Started')
      } else if (window.android) {
        window.android.showSpinner()
      }
      break

    case '[SPINNER] STOP':
      if (window.app_properties.onset) {
        store.dispatch({type: `SPINNER WEB FLAG`, payload: false})
      } else if (window.webkit) {
        window.webkit.messageHandlers.loadingstatus.postMessage('Loading Finished')
      } else if (window.android) {
        window.android.hideSpinner()
      }
      break
  }
}
