import React from 'react'

export default function Spinner() {
  return (
    <div data-testid="spinner" style={{minHeight: '50vh', display: 'flex'}}>
      <div className="fmc-activity-circle">
        <div className="fmc-activity-circle__progress" />
      </div>
    </div>
  )
}
