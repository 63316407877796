import {APP_CONSTANT} from '../../../components/helperComponents/Constants'
import {errorFlag} from '../../actions/ErrorActions'
import i18n from 'i18next'
import {BANNER_FEATURE, BANNER_TYPE} from '../../../components/error/ErrorBanner'

export const bffMiddleware = (store) => (next) => (action) => {
  next(action)

  function dispatchGenericErrorAction() {
    store.dispatch(
      errorFlag({
        state: BANNER_TYPE.ERROR,
        errorStatus: true,
        message: APP_CONSTANT.CONFIG.IS_OWNER
          ? i18n.t('lbl_generic_error_web_part1')
          : i18n.t(`lbl_generic_error_${APP_CONSTANT.CONFIG.BRAND_CODE}`),
        feature: BANNER_FEATURE.GENERIC,
      }),
    )
  }

  switch (action.type) {
    case 'BFF_AUTHORIZE_API':
        window.location.href =  process.env.NODE_ENV === 'production'
            ? `${APP_CONSTANT.CONFIG.APIGEE_URL}/api/privacybff/authorize?countryCode=${APP_CONSTANT.CONFIG.COUNTRY_CODE}&languageCode=${APP_CONSTANT.CONFIG.LANGUAGE_CODE}`
            // : `https://api.qa01e.gcp.ford.com/api/privacybff/authorize?countryCode=${APP_CONSTANT.CONFIG.COUNTRY_CODE}&languageCode=${APP_CONSTANT.CONFIG.LANGUAGE_CODE}`;
            // : `https://localpcu.ford.com:8080/api/privacybff/authorize?countryCode=${APP_CONSTANT.CONFIG.COUNTRY_CODE}&languageCode=${APP_CONSTANT.CONFIG.LANGUAGE_CODE}`;
            : 'https://localpcu.ford.com:8080/api/privacybff/authorize?countryCode=USA&languageCode=en-US';
      break

    case 'BFF_AGGREGATOR_API':
      try {
        store.dispatch({type: 'RESET MANAGE YOUR DATA SHARING DETAILS'})
        store.dispatch({type: 'RESET MANAGE PRIVACY SETTINGS DETAILS'})
        store.dispatch({type: 'RESET UNLINK THIRD PARTY ACCOUNTS'})
        store.dispatch({type: 'CALL EXPERIENCE LAYER API'})
      } catch (error) {
        console.log(
          `Error after fma login successfully or while setting the cat 1 token for source ${
            action.type
          } and error stack trace: ${error.toString()}`,
        )
        dispatchGenericErrorAction()
      }
      break

    case 'BFF_FORDPASS_AGGREGATOR_API':
      try {
        store.dispatch({type: 'RESET MANAGE YOUR DATA SHARING DETAILS'})
        store.dispatch({type: 'RESET MANAGE PRIVACY SETTINGS DETAILS'})
        store.dispatch({type: 'RESET UNLINK THIRD PARTY ACCOUNTS'})
        store.dispatch({type: 'CALL FORDPASS AGGREGATOR API'})
      } catch (error) {
        console.log(
            `Error after calling fordpass aggregator API ${
                action.type
            } and error stack trace: ${error.toString()}`,
        )
        dispatchGenericErrorAction()
      }
      break
  }
}
