import {APP_CONSTANT, FEATURE_UI_NAME} from '../../../components/helperComponents/Constants'
import {apiRequest} from '../../actions/ApiActions'
import {errorFlag} from '../../actions/ErrorActions'
import {BANNER_FEATURE, BANNER_TYPE} from '../../../components/error/ErrorBanner'
import {setLoadingFlag} from '../../actions/LoadingActions'
import i18n from 'i18next'
import {getBrandedTranslation} from '../../../components/helperComponents/contentUtils'
import { bffAggregatorAPI } from '../../actions/BffActions'

function displayErrorMsgPerUIName(uiName) {
  if (uiName === FEATURE_UI_NAME.amazonAlexa) {
    return APP_CONSTANT.CONFIG.IS_OWNER
      ? i18n.t('lbl_remove_consent_failure_web_part1')
      : getBrandedTranslation('lbl_remove_consent_failure')
  } else {
    return APP_CONSTANT.CONFIG.IS_OWNER
      ? i18n.t('lbl_maintain_privacy_error_web_part1')
      : getBrandedTranslation('lbl_maintain_privacy_error')
  }
}

function getSavePrivacyHeaders() {
  return {
      'Application-Id': APP_CONSTANT.CONFIG.APPLICATION_ID,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Headers': '*',
    'X-B3-SpanId': APP_CONSTANT.CONFIG.SPAN_ID,
    'X-B3-TraceId': APP_CONSTANT.CONFIG.TRACE_ID,
    CountryCode: APP_CONSTANT.CONFIG.COUNTRY_CODE,
  }
}

export const savePrivaciesMiddleware = (store) => (next) => (action) => {
  next(action)
  switch (action.type) {
    case '[MAINTAIN PRIVACY]': {
      if (APP_CONSTANT.CONFIG.IS_OWNER) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }

      store.dispatch({type: `[SPINNER] START`})

      next(
        apiRequest({
          body: action.payload.data,
          method: 'POST',
          url:
            process.env.NODE_ENV === 'production'
              ? `${APP_CONSTANT.CONFIG.APIGEE_URL}/api/privacybff/privacy?thirdPartyApplicationId=${action.payload.appId}`
              // : `https://api.qa01e.gcp.ford.com/api/privacybff/privacy?applicationId=${action.payload.appId}`,
              : `https://localpcu.ford.com:8080/api/privacybff/privacy?thirdPartyApplicationId=${action.payload.appId}`,
          feature: '[MAINTAIN PRIVACY]',
          uiName: action.payload.uiName,
          headers: getSavePrivacyHeaders(),
          responseType: 'json',
        }),
      )
      break
    }

    case '[MAINTAIN PRIVACY] API_SUCCESS':
      store.dispatch(bffAggregatorAPI())
      if (action.uiName === FEATURE_UI_NAME.amazonAlexa) {
        next(
          errorFlag({
            state: BANNER_TYPE.SUCCESS,
            errorStatus: true,
            message: i18n.t('lbl_remove_success'),
            feature: BANNER_FEATURE.SUCCESS_REMOVE,
          }),
        )
      }
      break

    case '[MAINTAIN PRIVACY] API_ERROR': {
      console.log(`Error from maintain privacy API for source ${action.type}`)

      next(
        errorFlag({
          state: BANNER_TYPE.ERROR,
          errorStatus: true,
          message: displayErrorMsgPerUIName(action.uiName),
          feature: BANNER_FEATURE.MAINTAIN_PRIVACY,
        }),
      )
      store.dispatch({type: `[SPINNER] STOP`})
      store.dispatch(setLoadingFlag({loadingFlag: true}))

      break
    }
  }
}
