import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import App from './App'
import {store} from './redux/Store'
import './i18n'

const rootElement = document.getElementById('app')
const root = createRoot(rootElement)

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
