import React, {useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {clearSelectedConsents} from '../../redux/actions/ConsentActions'
import {APP_CONSTANT, APPLICATION_PATH} from '../helperComponents/Constants'
import {dismissErrorBanner} from '../../redux/actions/ErrorActions'
import SVGIcon from '../SVGIcon'
import {useNavigate} from 'react-router-dom'
import {finishRefreshing, startRefreshing} from '../../redux/actions/LoadingActions'
import {useTranslation} from 'react-i18next'
import {useBrandedTranslation} from '../helperComponents/contentUtils'

export const BANNER_TYPE = Object.freeze({
  INFO: 'default',
  ERROR: 'error',
  SUCCESS: 'success',
  NONE: '',
})

export const BANNER_FEATURE = Object.freeze({
  GENERIC: 'GENERIC',
  EXPERIENCE_LAYER: 'EXPERIENCE-LAYER',
  CLOSE_BANNER: 'CLOSE-BANNER',
  SUCCESS_REMOVE: 'SUCCESS-REMOVE',
  REMOVE_CONSENT: 'REMOVE-CONSENT',
  MAINTAIN_PRIVACY: 'MAINTAIN-PRIVACY',
  REFRESH: 'REFRESH',
})

export default function ErrorBanner() {
  const errorFlagStore = useSelector((state) => state.errorFlag)
  const {loadingFlag, refreshingInProgress} = useSelector((state) => state.loading)
  const dispatch = useDispatch()
  const pageTitle = useRef(null)
  const navigate = useNavigate()
  const {t} = useTranslation()

  useEffect(() => {
    if (pageTitle.current !== null) pageTitle.current.focus()
  }, [])

  useEffect(() => {
    if (
      errorFlagStore &&
      errorFlagStore.feature === BANNER_FEATURE.SUCCESS_REMOVE &&
      errorFlagStore.status === true
    ) {
      setTimeout(() => {
        dispatch(dismissErrorBanner())
      }, 5000)
    }
  }, [errorFlagStore])

  useEffect(() => {
    if (refreshingInProgress && loadingFlag) {
      dispatch(finishRefreshing())
      dispatch({type: `[SPINNER] STOP`})
      removeErrorFlag()
      navigate(APPLICATION_PATH.MANAGE_DATA_WITH_THIRD_PARTIES, {replace: true})
    }
  }, [loadingFlag, refreshingInProgress])

  function getErrorMessage(errorStatus) {
    const ownerSupportLink = (
      <a
        className={`privacy-policy-link-${APP_CONSTANT.CONFIG.BRAND_CODE}`}
        href={useBrandedTranslation(`lbl_support_link`)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('lbl_generic_support')}
      </a>
    )

    return APP_CONSTANT.CONFIG.IS_OWNER ? (
      <>
        {`${errorStatus.message} `}
        {ownerSupportLink}
        {` ${t('lbl_error_site_for_help_web_part2')} `}
      </>
    ) : (
      <>{errorStatus.message}</>
    )
  }

  function removeErrorFlag() {
    switch (errorFlagStore.feature) {
      case BANNER_FEATURE.EXPERIENCE_LAYER:
        dispatch(dismissErrorBanner())
        break

      case BANNER_FEATURE.REMOVE_CONSENT:
        dispatch(dismissErrorBanner())
        dispatch(clearSelectedConsents())
        break

      default:
        dispatch(dismissErrorBanner())
        break
    }
  }

  function handleRefreshClicked() {
    dispatch(startRefreshing())
  }

  function getTopBannerModifierClassname() {
    if (errorFlagStore.state) return `fmc-top-banner--${errorFlagStore.state}`
  }

  function renderCloseButton() {
    if (
      errorFlagStore.feature !== BANNER_FEATURE.SUCCESS_REMOVE &&
      errorFlagStore.feature !== BANNER_FEATURE.REFRESH
    )
      return (
        <button
          className="fmc-top-banner__dismiss fds-icon fds-font--ford-icons__clear fds-icon--offset-right"
          aria-label="close"
          aria-hidden={String(!errorFlagStore.status)}
          onClick={removeErrorFlag}
        />
      )
  }

  function renderRefreshButton() {
    if (errorFlagStore.feature === BANNER_FEATURE.REFRESH)
      return (
        <button
          className="fmc-top-banner__dismiss error-banner__refresh-button"
          aria-label="close"
          aria-hidden={String(!errorFlagStore.status)}
          onClick={handleRefreshClicked}
        >
          {APP_CONSTANT.CONFIG.BRAND_CODE === 'F' ? (
            <SVGIcon name="error_refresh" className="close-icon" width="16px" height="16px" />
          ) : (
            <SVGIcon name="lincoln_refresh" className="close-icon" width="16px" height="16px" />
          )}
        </button>
      )
  }

  return (
    <div
      className={`error-banner fmc-top-banner ${getTopBannerModifierClassname()}`}
      data-testid="error-banner"
      hidden={!errorFlagStore.status}
    >
      <div className="fmc-top-banner__inner">
        <span className="fmc-top-banner__content">
          {errorFlagStore.state === BANNER_TYPE.ERROR
            ? getErrorMessage(errorFlagStore)
            : errorFlagStore.message}
        </span>

        {renderCloseButton()}
        {renderRefreshButton()}
      </div>
    </div>
  )
}
